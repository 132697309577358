// MUI
// ----------------------------------------------------------------------

export const MUI_LICENSE = process.env.REACT_APP_MUI_X;

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const CIPHER_COGNITO = 'd0fd0a13e07c4cb1'; // 36 bytes

export const LOCAL_DOMAINS = ["localhost", "http://192.168.27.30"];

export const COGNITO_API = {
  region: 'us-east-1',
  CognitoID: 'us-east-1_vjv0hCrDv',
  CognitoClientID: '1fle070t4n7dcbgsnrd206m8nv',
  CognitoIdentityPoolId: 'us-east-1:fc3e48f3-3453-4fad-8e69-f64747d9d5ca',
  CognitoDomainName: 'https://intranetapp-vi.auth.us-east-1.amazoncognito.com',
  accessKeyId: process.env.REACT_APP_AWS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_PASS,
  bucketSorage: 'vi-intranet-storage',
};

export const MENU_SECTIONS = ['Home', 'Relatórios CEV', 'Indicadores', 'Produtos', 'Bônus', 'Campanhas', 'Rankings', 'Ferramentas', 'Outros'];

export const REPORT_IN_GROUP_API = 'https://818k3p8zwg.execute-api.us-east-1.amazonaws.com/Stage';

export const CLIENTS_GROUP_API = 'https://fadgxnurg8.execute-api.us-east-1.amazonaws.com/prod';

export const PROFILES_API = 'https://x4l2x4h2e5.execute-api.us-east-1.amazonaws.com/prod';

export const UPLOADS_API = 'https://yndf50viqc.execute-api.us-east-1.amazonaws.com/prod';

export const CAMPAIGNS_API = 'https://txm1vcqb50.execute-api.us-east-1.amazonaws.com/prod';

export const TRANSFER_API = 'https://d1zvtwfs62.execute-api.us-east-1.amazonaws.com/Stage';

export const PEOPLE_API = 'https://33pnijjkb4.execute-api.us-east-1.amazonaws.com/prod';

export const POSTS_API = 'https://qr9m1n4bjj.execute-api.us-east-1.amazonaws.com/prod';

export const COGNITO_ENDPOINT_API = 'https://jgulvp60ki.execute-api.us-east-1.amazonaws.com/prod';

export const ROOMS_API = 'https://ptonb3bos1.execute-api.us-east-1.amazonaws.com/prod';

export const PIPE_API = 'https://f5dza34mfa.execute-api.us-east-1.amazonaws.com/prod';

export const ROUTES_API = 'https://3fox1fkx69.execute-api.us-east-1.amazonaws.com/prod';

export const GESTAO_A_VISTA_API = 'https://5apzjlzwed.execute-api.us-east-1.amazonaws.com/prod';

export const ANNOUNCEMENTS_API = 'https://wbgb7k46f9.execute-api.us-east-1.amazonaws.com/prod';

export const NATIONAL_SCHEDULE_API = 'https://10cwnu48ec.execute-api.us-east-1.amazonaws.com/prod'

export const INTERNAL_TRANSFERS_API = 'https://kh49eh0b5l.execute-api.us-east-1.amazonaws.com/prod';

export const PRODUCTS_API = 'https://cyspbftzwj.execute-api.us-east-1.amazonaws.com/prod';

export const VALOR_STORE_API = 'https://1b5pro8wv2.execute-api.us-east-1.amazonaws.com/prod';

export const BACKOFFICE_TICKETS_API = 'https://i89oxtlo5g.execute-api.us-east-1.amazonaws.com/prod';

export const VCS_TICKETS_API = 'https://b8n4vpon3j.execute-api.us-east-1.amazonaws.com/prod';

export const TI_TICKETS_API = 'https://mb6iphh58i.execute-api.us-east-1.amazonaws.com/prod';

export const EVENTS_CONTROL_API = 'https://rjn3e8xy19.execute-api.us-east-1.amazonaws.com/prod';

export const RKYC_API = 'https://yj30o8j4va.execute-api.us-east-1.amazonaws.com/prod';

export const DOCUMENTS_API = 'https://k4hsw5t4u4.execute-api.us-east-1.amazonaws.com/prod';

export const VALOR_CLUB_API = 'https://3gojwsfu4b.execute-api.us-east-1.amazonaws.com/prod';

export const EVENTS_API = 'https://idw2827wa0.execute-api.us-east-1.amazonaws.com/prod';

export const TRIGGER_API = 'https://s1jyj7q6v1.execute-api.us-east-1.amazonaws.com/prod';

export const PORTFOLIO_API = 'https://portfolio.valorinvestimentos.com.br';

export const PORTFOLIO_LINK_API = 'https://e6efxamvt4.execute-api.us-east-1.amazonaws.com/prod';

export const TRAINING_API = ' https://ot5sannymi.execute-api.us-east-1.amazonaws.com/prod';

export const VGR_API = 'https://rdu1215ndh.execute-api.us-east-1.amazonaws.com/prod/';

export const COMISSIONS_API = 'https://2lczzycytk.execute-api.us-east-1.amazonaws.com/prod';

export const PROCESSED_UPLOADS_API = 'https://rjtrni4vw8.execute-api.us-east-1.amazonaws.com/prod';

export const FILES_MANAGEMENT_API = 'https://9pwqlp7kif.execute-api.us-east-1.amazonaws.com/prod';

export const HUB_XP_RUNNER = 'https://mjwz6xsve1.execute-api.us-east-1.amazonaws.com/prod/';

export const MAPBOX_API = process.env.REACT_APP_MAPBOX;

export const GOOGLE_ANALYTICS_API = process.env.REACT_APP_GA_MEASUREMENT_ID;

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 280,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themePowerBiDirection: 'ltr',
  themeColorPresets: 'default',
  themeStretch: false,
};
